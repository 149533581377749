<template>
	<v-dialog
		@click:outside="onAddSubmitCancel"
		@keydown.esc="onAddSubmitCancel"
		:value="open"
		max-width="500px">
		<v-card>
			<v-card-title :class="['primary', 'font-weight-bold']">
				<span class="text-h5">
					{{ editedItem.id === null ? "Create" : "Edit" }} group config
				</span>
			</v-card-title>

			<v-card-text>
				<v-container>
					<v-form
						ref="form"
						v-model="validEdit">
						<v-row>
							<v-col
								v-if="editedItem.id != null"
								cols="12"
								class="mt-0 pt-0">
								<div class="text-h6 d-flex justify-space-between mt-0 pt-0">
									<span>
										ID:
										<span class="primary--text font-weight-bold">
											{{ editedItem.id }}
										</span>
									</span>
									<v-tooltip
										v-if="
											!this.getListGroupsWithConfig.isLoading &&
											groupsWithConfigsLength > 0
										"
										left
										color="error darken-2">
										<template v-slot:activator="{ on: tooltip }">
											<v-btn
												fab
												elevation="0"
												color="red"
												x-small
												v-on="tooltip">
												<v-icon>mdi-exclamation</v-icon>
											</v-btn>
										</template>
										<span>
											<h4>Groups using this config:</h4>
											<ul>
												<li
													v-for="group of groupsWithConfigs"
													v-bind:key="group.group_id">
													{{ group.name }} ({{ group.group_id }})
												</li>
											</ul>
											Editing this config will modify it for all groups.
										</span>
									</v-tooltip>
								</div>
							</v-col>
							<!-- :rules="[rules.required, rules.positiveNumber, editedItem.contrib_type_id == 1 ? rules.mindfuck : rules.currency]" -->
							<v-col cols="12">
								<v-text-field
									v-model.number="editedItem.contrib"
									label="Contrib"
									:rules="[
										rules.required,
										rules.positiveNumber,
										rules.mindfuck,
									]"></v-text-field>
							</v-col>

							<v-select
								:disabled="editedItem.id !== null"
								class="col-12"
								label="Contrib Type"
								v-model="editedItem.contrib_type_id"
								:items="contribTypeDropdown"
								item-text="contribType"
								item-value="id"
								:rules="[rules.required, rules.positiveNumber]"
								type="text" />

							<v-select
								class="col-12"
								label="Currency"
								v-model="editedItem.currency"
								:items="currencyCodes"
								:rules="[rules.required, rules.currency]"
								type="text" />
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>

			<v-card-actions class="justify-space-between">
				<v-btn
					color="gray darken-1"
					text
					@click="onAddSubmitCancel">
					Cancel
				</v-btn>
				<!-- TODO: [L2-101] ADD CHECK FOR NO EDIT MADE -->
				<v-btn
					color="primary"
					text
					:disabled="!validEdit || !isEdited"
					@click="onAddEditSubmit">
					Submit
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { rules as defaultRules } from "../../plugins/rules.ts";
import { currencyCodes } from "../../constants/currencyCodes";
import _ from "lodash";

export default {
	data() {
		return {
			currencyCodes,
			rules: {
				...defaultRules,
				currency: () => {
					return true;
				},
			},
			editedItem: this.item,
			original: JSON.parse(JSON.stringify(this.item)),
			validEdit: false,
			contribTypeDropdown: [
				{ id: 1, contribType: "Percentage" },
				{ id: 2, contribType: "Bet" },
			],
		};
	},
	computed: {
		...mapGetters(["getListGroupsWithConfig"]),
		...mapGetters("jackpotGroup2", {
			list: "list",
		}),
		isEdited() {
			if (!this.editedItem.id) return true;
			return !_.isEqual(this.original, this.editedItem);
		},
		groupsWithConfigs() {
			return this.list;
		},
		groupsWithConfigsLength() {
			return this.groupsWithConfigs?.length ?? 0;
		},
	},
	watch: {
		item(newVal) {
			this.editedItem = newVal;
			this.original = JSON.parse(JSON.stringify(newVal));
			if (!this.editedItem.id) return;
			this.loadGroups({
				groupConfigId: this.editedItem.id,
			});
			// this.getListGroupsWithConfig.data.data.data.map((el)=> )
		},
	},
	methods: {
		...mapMutations(["setListGroupConfigsData"]),
		...mapActions(["loadGroupsListWithConfig"]),
		...mapActions("jackpotGroup2", {
			loadGroups: "loadGroupsWithConfig",
		}),
		...mapActions("jackpotGroupConfig2", {
			createItem: "create",
			updateItem: "update",
		}),
		async onAddSubmitCancel() {
			this.$emit("cancel");
		},
		async onAddEditSubmit() {
			this.$refs.form.validate();
			await this.$nextTick();
			if (!this.validEdit) return;
			if (this.editedItem.id === null) await this.createItem(this.editedItem);
			else
				await this.updateItem({
					id: this.editedItem.id,
					data: this.editedItem,
				});
			this.$emit("submit");
		},
	},
	props: {
		item: {
			type: Object,
			default: undefined,
		},
		open: {
			type: Boolean,
			defaul: false,
		},
	},
};
</script>
