var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.open, "max-width": "500px" },
      on: {
        "click:outside": _vm.onAddSubmitCancel,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.onAddSubmitCancel.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { class: ["primary", "font-weight-bold"] }, [
            _c("span", { staticClass: "text-h5" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.editedItem.id === null ? "Create" : "Edit") +
                  " group config "
              ),
            ]),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.validEdit,
                        callback: function ($$v) {
                          _vm.validEdit = $$v
                        },
                        expression: "validEdit",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _vm.editedItem.id != null
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "mt-0 pt-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-h6 d-flex justify-space-between mt-0 pt-0",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(" ID: "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "primary--text font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.editedItem.id) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      !this.getListGroupsWithConfig.isLoading &&
                                      _vm.groupsWithConfigsLength > 0
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                left: "",
                                                color: "error darken-2",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on: tooltip,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                fab: "",
                                                                elevation: "0",
                                                                color: "red",
                                                                "x-small": "",
                                                              },
                                                            },
                                                            tooltip
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-exclamation"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                4224546900
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _c("h4", [
                                                  _vm._v(
                                                    "Groups using this config:"
                                                  ),
                                                ]),
                                                _c(
                                                  "ul",
                                                  _vm._l(
                                                    _vm.groupsWithConfigs,
                                                    function (group) {
                                                      return _c(
                                                        "li",
                                                        { key: group.group_id },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                group.name
                                                              ) +
                                                              " (" +
                                                              _vm._s(
                                                                group.group_id
                                                              ) +
                                                              ") "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _vm._v(
                                                  " Editing this config will modify it for all groups. "
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Contrib",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.positiveNumber,
                                    _vm.rules.mindfuck,
                                  ],
                                },
                                model: {
                                  value: _vm.editedItem.contrib,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editedItem,
                                      "contrib",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "editedItem.contrib",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-select", {
                            staticClass: "col-12",
                            attrs: {
                              disabled: _vm.editedItem.id !== null,
                              label: "Contrib Type",
                              items: _vm.contribTypeDropdown,
                              "item-text": "contribType",
                              "item-value": "id",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.positiveNumber,
                              ],
                              type: "text",
                            },
                            model: {
                              value: _vm.editedItem.contrib_type_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "contrib_type_id", $$v)
                              },
                              expression: "editedItem.contrib_type_id",
                            },
                          }),
                          _c("v-select", {
                            staticClass: "col-12",
                            attrs: {
                              label: "Currency",
                              items: _vm.currencyCodes,
                              rules: [_vm.rules.required, _vm.rules.currency],
                              type: "text",
                            },
                            model: {
                              value: _vm.editedItem.currency,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "currency", $$v)
                              },
                              expression: "editedItem.currency",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-space-between" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "gray darken-1", text: "" },
                  on: { click: _vm.onAddSubmitCancel },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    text: "",
                    disabled: !_vm.validEdit || !_vm.isEdited,
                  },
                  on: { click: _vm.onAddEditSubmit },
                },
                [_vm._v(" Submit ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }